// ** Initial State
const initialState = {
    data: [],
    events: [],
    memberships: [],
    videoCategories: [],
    cryptoVideos: [],
    total: 1,
    isFetching: false
}

const transactions = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_TRANSACTIONS':
            return {
                ...state,
                data: (action.data && action.data) || [],
                total: (action.total && action.total) || 1,
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ALL_MEMBERSHIPS':
            return {
                ...state,
                memberships: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ALL_EVENTS':
            return {
                ...state,
                events: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ALL_VIDEO_CATEGORIES':
            return {
                ...state,
                videoCategories: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ALL_CRYPTO_VIDEOS':
            return {
                ...state,
                cryptoVideos: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        default:
            return { ...state }
    }
}

export default transactions