// ** Initial State
const initialState = {
    liveNfts: [],
    activeNfts: [],
    liveTokens: [],
    activeTokens: [],
    liveNewsFeed: [],
    activeNewsFeed: [],
    total: 1,
    isFetching: false,
    isActiveFetching: false
}

const nfts = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LIVE_NFTS':
            return {
                ...state,
                liveNfts: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ACTIVE_NFTS':
            return {
                ...state,
                activeNfts: (action.data && action.data) || [],
                isActiveFetching: (action.isActiveFetching && action.isActiveFetching) || false,
                params: action.params
            }
        case 'GET_LIVE_NEWSFEED':
            return {
                ...state,
                liveNewsFeed: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ACTIVE_NEWSFEED':
            return {
                ...state,
                activeNewsFeed: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_LIVE_TOKENS':
            return {
                ...state,
                liveTokens: (action.data && action.data) || [],
                isFetching: (action.isFetching && action.isFetching) || false,
                params: action.params
            }
        case 'GET_ACTIVE_TOKENS':
            return {
                ...state,
                activeTokens: (action.data && action.data) || [],
                isActiveFetching: (action.isActiveFetching && action.isActiveFetching) || false,
                params: action.params
            }
        default:
            return { ...state }
    }
}

export default nfts