// ** Initial State
const initialState = {
    data: [],
    total: 1,
    isFetching: false
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_DATA':
            return {
              ...state,
              data: (action.data && action.data) || [],
              total: (action.total && action.total) || 1,
              isFetching: (action.isFetching && action.isFetching) || false,
              params: action.params
            }
        default:
            return { ...state }
    }
}

export default users