// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from './users'
import transactions from './transactions'
import api from './api'

const rootReducer = combineReducers({
  auth,
  users,
  transactions,
  navbar,
  layout,
  api
})

export default rootReducer
